import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from '@emotion/styled'
import parse from 'html-react-parser'

import Layout from '../layouts'
import * as styles from './styles.module.css'
import Helmet from 'react-helmet'

import BlogPostMenuBar from '../components/BlogPostMenuBar'
import MiniBio from '../components/MiniBio'
import BlogPost from '../interfaces/BlogPost'

const ArticleNavWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
`

const PrevArticleNav = styled.li`
  margin: 12px 0;
  float: right;
`

const NextArticleNav = styled.li`
  margin: 12px 0;
`

const ArticleNavLink = styled(Link)`
  font-size: 20px;
  @media only screen and (max-width: 640px) {
    font-size: 18px;
  }
`

/**
 * Adds classes to HTML retrieved by gatsby-transformer-remark to
 * apply custom CSS. Kind of feels hacky but I couldn't figure out a different
 * way to apply different max-width attributes to images and text.
 *
 * @param {string} input HTML passed from gatsby-transformer-remark.
 */
const processBlogPostHtml = (input: string) => {
  return input
    .replace(/<p>/g, '<p class="article-paragraph">')
    .replace(/<ul>/g, '<ul class="article-paragraph">')
    .replace(/<li>/g, '<li class="article-paragraph">')
    .replace(/<h2>/g, '<h2 class="article-header">')
    .replace(/<blockquote>/g, '<blockquote class="article-blockquote">')
    .replace(/<figcaption>/g, '<figcaption class="article-caption">')
    .replace(
      /<p class="article-paragraph"><span/g,
      '<p class="article-image"><span'
    )
    .replace(
      '<p class="article-paragraph">',
      '<p class="article-paragraph first-paragraph">'
    )
  // Last replacement applies to first paragraph only.
}

class BlogPostPage extends React.Component<BlogPostProps> {
  render() {
    const { markdownRemark } = this.props.data
    const { frontmatter, html } = markdownRemark
    const { previous, next } = this.props.pageContext
    const { excerpt } = markdownRemark
    const processedHtml = processBlogPostHtml(html)

    return (
      <Layout>
        <Helmet>
          <title>{frontmatter.title}</title>
          <meta name="description" content={excerpt} />

          {/* Metadata for Open Graph */}
          <meta property="og:title" content={frontmatter.title} />
          <meta
            property="og:url"
            content={this.props.data.site.siteMetadata.siteUrl}
          />
          <meta property="og:description" content={excerpt} />
        </Helmet>
        <BlogPostMenuBar title={frontmatter.title} />
        <article className={styles.article}>
          <div className={styles.articleHead}>
            <h1 className={styles.articleTitle}>{frontmatter.title}</h1>
            <small className={styles.articleDate}>{frontmatter.date}</small>
          </div>
          <div className={'blog-post'}>{parse(processedHtml)}</div>
          <hr />
        </article>

        <MiniBio />

        <nav style={{ maxWidth: '600px', margin: '0 auto 80px' }}>
          <ArticleNavWrapper>
            <PrevArticleNav>
              {previous && (
                <ArticleNavLink to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </ArticleNavLink>
              )}
            </PrevArticleNav>
            <NextArticleNav>
              {next && (
                <ArticleNavLink to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </ArticleNavLink>
              )}
            </NextArticleNav>
          </ArticleNavWrapper>
        </nav>
      </Layout>
    )
  }
}

interface BlogPostProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        siteUrl: string
      }
    }
    markdownRemark: BlogPost['node']
  }
  pageContext: {
    previous: BlogPost['node']
    next: BlogPost['node']
  }
}

export const query = graphql`
  query ($slug: String) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`

export default BlogPostPage
