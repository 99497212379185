import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'

export const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  margin-bottom: 30px;
`

const ImageWrapper = styled.div`
  width: 160px;
  height: 72px;
  margin: 24px auto;
`

export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "profile.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 72
              height: 72
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const image = getImage(data.file)
  return (
    <Wrapper>
      <ImageWrapper>
        {image && <GatsbyImage image={image} alt="Ryosuke Minami" />}
      </ImageWrapper>
      <p style={{ paddingLeft: '20px' }}>
        <strong>Ryosuke Minami</strong>
        {` is a student currently studying 
        computer science and philosophy in Scotland. Originally from Japan
        and the United States.`}
      </p>
    </Wrapper>
  )
}
