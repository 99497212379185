import styled from '@emotion/styled'
import { Link } from 'gatsby'

export const Logo = styled.img`
  height: 30px;
  margin: 10px 16px 10px 18px;
`

export const TitleText = styled.div`
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 700;
  z-index: inherit;
`

export const LeftSection = styled(Link)`
  display: flex;
  width: 220px;
  float: left;
  box-shadow: none;
  text-decoration: none;
  color: inherit;
  align-items: center;
  z-index: inherit;
  background-color: #ffffff;
  &:hover {
    text-decoration: none;
  }
`

export const CenterSection = styled.div`
  float: none;
  left: 50%;
  text-align: center;
`

export const CenterText = styled.div`
  font-family: Charter, Georgia, 'Times New Roman', Times, serif;
  font-size: 16px;
  font-style: italic;
  color: #333333;

  @media only screen and (max-width: 720px) {
    display: none;
  }
`

export const RightSection = styled.div`
  float: right;
  width: 220px;
  text-align: right;
  vertical-align: middle;

  @media only screen and (max-width: 720px) {
    width: 54px;
  }
`

export const Wrapper = styled.div`
  position: fixed;
  background-color: #ffffff;
  top: -100px;
  left: 0;
  height: 54px;
  width: 100%;
  z-index: 10;
  transition: 0.3s ease-out;
  /* box-shadow: rgba(0,0,0,0.08) 0 0 5px 1px; */
  border-bottom: 0.5px solid #dddddd;
  /* box-shadow: 0 0 3px rgba(0,0,0,.03), 0 3px 46px rgba(0,0,0,.07); */
  /* border-bottom: rgb(52, 63, 68); */
  &.visible {
    top: 0;
  }
`

export const ProgressBarWrapper = styled.div`
  position: fixed;
  height: 3px;
  width: 100%;
  background-color: #ffffff;
  z-index: inherit;
`

export const MenuBarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
  position: fixed;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  z-index: inherit;
`

interface ProgressBarProps {
  scroll: number
}

export const ProgressBar = styled.div`
  position: fixed;
  width: 100%;
  background: linear-gradient(
    to right,
    #15b5e6 ${(props: ProgressBarProps) => `${props.scroll}%`},
    transparent 0
  );
  opacity: 0.5;
  height: 3px;
`
