import React from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import HamburgerButton from '../HamburgerButton'
import HamburgerContent from '../HamburgerContent'
import NavItem from '../../interfaces/NavItem'

import logo from '../../assets/logo.svg'

import {
  Logo,
  TitleText,
  Wrapper,
  ProgressBarWrapper,
  MenuBarWrapper,
  ProgressBar,
  LeftSection,
  CenterSection,
  CenterText,
  RightSection
} from './styles'

interface BlogPostMenuBarProps {
  title: string
  docHeight?: number
}

interface BlogPostMenuBarState {
  isVisible: boolean
  progress: number
  isOpen: boolean
}

class BlogPostMenuBar extends React.Component<
  BlogPostMenuBarProps,
  BlogPostMenuBarState
> {
  docHeight?: number
  constructor(props: BlogPostMenuBarProps) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleTitleClick = this.handleTitleClick.bind(this)
    this.handleMenuToggle = this.handleMenuToggle.bind(this)
    this.docHeight = undefined
    this.state = {
      isVisible: false,
      progress: 0,
      isOpen: false
    }
  }

  componentDidMount() {
    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    )
    this.docHeight = scrollHeight - window.innerHeight
    window.addEventListener('scroll', this.handleScroll, {
      capture: false,
      passive: true
    })
  }

  handleScroll(e: Event) {
    if (this.docHeight != null) {
      this.setState({
        progress: Math.floor((window.pageYOffset * 100.0) / this.docHeight)
      })
    }
    if (window.pageYOffset > 200) {
      this.setState({ isVisible: true })
    } else {
      this.setState({ isVisible: false })
    }
  }

  handleTitleClick() {
    // When the title is clicked, scrolling should be enabled regardless
    // of whether or not the menu is open.
    enableBodyScroll(document.body)
  }

  handleMenuToggle() {
    if (!this.state.isOpen) {
      // When opening the hamurger menu, disable scrolling while menu is open.
      disableBodyScroll(document.body)
    } else {
      // When closing the hamburger menu, reenable scrolling.
      enableBodyScroll(document.body)
    }
    this.setState({ isOpen: !this.state.isOpen })
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    const navItems: NavItem[] = [
      { title: 'Home', to: '/' },
      { title: 'About', to: '/about' },
      { title: 'Blog', to: '/blog' }
    ]
    const isVisible = this.state.isVisible ? 'visible' : ''
    return (
      <Wrapper className={isVisible}>
        <ProgressBarWrapper>
          <ProgressBar scroll={this.state.progress} />
        </ProgressBarWrapper>
        <MenuBarWrapper>
          <LeftSection onClick={this.handleTitleClick} to={'/'}>
            <Logo src={logo} alt="logo" />
            <TitleText>{'Ryosuke Minami'}</TitleText>
          </LeftSection>
          <CenterSection onClick={this.scrollToTop}>
            <CenterText>{`— ${this.props.title} —`}</CenterText>
          </CenterSection>
          <RightSection>
            <HamburgerButton
              isOpen={this.state.isOpen}
              onClick={this.handleMenuToggle}
            />
          </RightSection>
        </MenuBarWrapper>
        <HamburgerContent
          isOpen={this.state.isOpen}
          onClick={this.handleMenuToggle}
          navItems={navItems}
        />
      </Wrapper>
    )
  }
}

export default BlogPostMenuBar
